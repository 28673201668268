import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchToGetLoggedInUserDetails } from '../reduxStore/authentication/userAuthSlice';

const HeaderImage = ({ src }) => (
  <div className="h-32 overflow-hidden">
    <img className="w-full" src={src} alt="" />
  </div>
);

const ProfileImage = ({ src }) => (
  <div className="flex justify-center px-5 -mt-12">
    <img className="h-32 w-32 bg-white p-2 rounded-full" src={src} alt="" />
  </div>
);

const ProfileDetails = ({ name, role, email, description }) => (
  <div className="text-center px-14">
    <h2 className="text-teal-800 text-3xl font-bold">{name}</h2>
    {/* <a className="text-teal-400 mt-2 hover:text-blue-500" href={instagram} target="_blank" rel="noopener noreferrer">@{instagram.split('/').pop()}</a> */}
    <a className="text-teal-400 font-semibold mt-2 hover:text-teal-500"  rel="noopener noreferrer">{role}</a>
    <br />
    <a className="text-teal-400 font-semibold mt-2 hover:text-teal-500"  rel="noopener noreferrer">{email}</a>
    <p className="mt-2 text-black-500 text-sm">{description}</p>
  </div>
);

const Stats = ({ followers, following }) => (
  <div className="flex bg-teal-50">
    <div className="text-center w-full p-4 hover:bg-teal-100 cursor-pointer">
      <a href='https://github.com/ankit76350' target='_blank'><span className="font-semibold">{followers} </span> Developed and Maintain  by Ankit Kumar</a>

    </div>

    {/* <div className="border"></div> */}
    {/* <div className="text-center w-1/2 p-4 hover:bg-teal-100 cursor-pointer">
      <p><span className="font-semibold">{following} </span> Logout</p>
    </div> */}
    
  </div>
);

const ProfileCard = () => {
  const state = useSelector((state) => state.userAuthReducer)
  const dispatch = useDispatch();
  const [userProfile,setUserProfile]= useState()
  useEffect(() => {
    dispatch(fetchToGetLoggedInUserDetails());
  }, [dispatch]);

  useEffect(() => {
    setUserProfile(state.user?.loggedInUser)
    //  console.log(state.user?.loggedInUser?.name);
  }, [state]);
  

  return (
  <div className="h-screen mx-4 mt-4 flex flex-wrap items-center justify-center">
    <div className="container lg:w-2/6 xl:w-2/7 sm:w-full md:w-2/3 bg-white shadow-lg transform duration-200 ease-in-out">
      <HeaderImage src="../assets/images/bgProfile.png" />
      <ProfileImage src="../assets/images/profile.png" />
      <div>
        <ProfileDetails 
          name={userProfile?.name} 
          role={userProfile?.role} 
          email={userProfile?.email} 
          description="
We want to take a moment to express our gratitude for your hard work and dedication. You are not just employees here; you are changemakers. Each day you come to work, you are contributing to something much bigger – you are helping save the environment for future generations.

By being part of Dharaksha, you are reducing pollution, transforming waste into something valuable, and paving the way for a greener tomorrow. " 
        />
        <hr className="mt-6" />
        <Stats followers="" following="" />
      </div>
    </div>
  </div>
);
}
export default ProfileCard;
