import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../assets/Loader';
import { fetchLcPreparationData } from '../../reduxStore/LcPreparation/LcPreparationSlice';
import Pagination from '../../assets/Pagination';
import FormatTime from '../../assets/FormatTime'

const DataOfLcPreparation = () => {
    const state = useSelector((state) => state.lcPreparationReducer); 
    const dispatch = useDispatch();
    const [getAllDataOfPrepareLc, setGetAllDataOfPrepareLc] = useState([]);
    const [totalNumberOfData, setTotalNumberOfData] = useState()
    const [resultPerPage, setResultPerPage] = useState()

    useEffect(() => {
        dispatch(fetchLcPreparationData());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.dataOfAllPreparedLc) {
            setGetAllDataOfPrepareLc(state.data.dataOfAllPreparedLc)
            setTotalNumberOfData(state.data.totalDataOfJamPreparation)
            setResultPerPage(state.data.resultPerPage)

        }
    }, [state]);

    return (
        <>
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block">
                    <div className="border rounded-lg shadow overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">S.No</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Quantity(in ml)</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">SC Id</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">SC Quantity(in ml.)</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Preparation Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Prepared By</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {state.isLoading ? (
                                    <tr>
                                        <td colSpan={'8'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : (
                                    getAllDataOfPrepareLc && getAllDataOfPrepareLc.map((currData,index) => (
                                        <tr className='hover:bg-teal-300' key={currData._id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{index+1}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcId}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcQty}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.scId}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.scQty}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.lcPreparationTime)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcPreparedBy}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Pagination getData={fetchLcPreparationData} totalData={totalNumberOfData} resultPerPage={resultPerPage}/>
        </>
    );
};

export default DataOfLcPreparation;
