import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../assets/Loader';
import { fetchToGetRejectedMoldData, fetchToDeleteRejectedMouldData, fetchToUpdateRejectedMouldFillingData } from '../../../reduxStore/MouldIncubation/MouldIncubationSlice';
import FormatTime from '../../../assets/FormatTime';

const AdminDataOfMouldIncubation = () => {
    const state = useSelector((state) => state.mouldIncubationReducer);
    const dispatch = useDispatch();
    const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);
    const [updatingId, setUpdatingId] = useState(null);
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [fillingDataForUpdateTheCurrValue, setFillingDataForUpdateTheCurrValue] = useState({});

    useEffect(() => {
        dispatch(fetchToGetRejectedMoldData());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.mouldRejectedData) {
            setGetAllDataFromCollection(state.data.mouldRejectedData);
        }
    }, [state]);

    const deleteData = async (id) => {
        if (window.confirm("Do you really want to delete?")) {
            
            dispatch(fetchToDeleteRejectedMouldData(id)).then(() => {
                dispatch(fetchToGetRejectedMoldData());
            });
        }
    };

    const updateData = (id) => {
        setUpdatingId(id);
        setDisabledButtons(true);
        const updatingData = getAllDataFromCollection.find((currItem) => currItem._id === id);
        if (updatingData) {
            setFillingDataForUpdateTheCurrValue(updatingData);
        } else {
            console.error("No data found for the provided id");
        }
    };

    const handleInputData = (key, value) => {
        setFillingDataForUpdateTheCurrValue({ ...fillingDataForUpdateTheCurrValue, [key]: value });
    };

    const save = async (id) => {
        const { __v, _id, previousDetailsTillMouldFilling, rejectionTime, ...onlyRequiredValue } = fillingDataForUpdateTheCurrValue;

        dispatch(fetchToUpdateRejectedMouldFillingData({ id, enteredData: onlyRequiredValue })).then(() => {
            setUpdatingId(null);
            setDisabledButtons(false);
            setFillingDataForUpdateTheCurrValue({});
            dispatch(fetchToGetRejectedMoldData());
        });
    };

    const updateCancel = () => {
        setUpdatingId(null);
        setDisabledButtons(false);
        setFillingDataForUpdateTheCurrValue({});
    };

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block">
                    <div className="border rounded-lg shadow overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">S.No.</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Reason Of Rejection</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Type Of Mould</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Rejection Time</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould Filling Time</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould Rejected By</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Remove</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Update</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {state.isLoading &&
                                    <tr>
                                        <td colSpan={'6'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                }

                                {(Array.isArray(getAllDataFromCollection) ? getAllDataFromCollection : []).map((currData,index) => (
                                    <tr className='hover:bg-teal-300' key={currData._id}>
                                        {updatingId && currData._id === updatingId ? (
                                            <>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.mouldId} name="mouldId" placeholder="Mould Id" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.reasonOfRejection} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="reasonOfRejection" placeholder="Reason Of Rejection" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.previousDetailsTillMouldFilling.typeOfMould}  name="typeOfMould" placeholder="Type Of Mould" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={FormatTime(fillingDataForUpdateTheCurrValue.rejectionTime)} name="rejectionTime" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={FormatTime(fillingDataForUpdateTheCurrValue.previousDetailsTillMouldFilling.fillingDate)}  name="fillingDate" placeholder="Mould Filling Date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.mouldRejectedBy} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="mouldRejectedBy" placeholder="Mould Rejected By" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { deleteData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Remove
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { save(currData._id) }} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-500 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none mr-2">
                                                        Save
                                                    </button>
                                                    <button type="button" onClick={() => { updateCancel(currData._id) }} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-yellow-600 hover:text-yellow-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Cancel
                                                    </button>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{index+1}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.mouldId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.reasonOfRejection}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousDetailsTillMouldFilling.typeOfMould}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.rejectionTime)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.previousDetailsTillMouldFilling.fillingDate)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.mouldRejectedBy}</td>
                                                {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{JSON.stringify(currData.previousDetailsTillMouldFilling)}</td> */}
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { deleteData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Remove
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { updateData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-500 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Update
                                                    </button>
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminDataOfMouldIncubation;
