import React, { useState, createRef, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchToPrepareNewLc } from '../../reduxStore/LcPreparation/LcPreparationSlice';
import QRScanner from '../../assets/QRScanner';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateNewLc = () => {
  const dispatch = useDispatch();
  const [lcPreparationData, setLcPreparationData] = useState({
    lcId: ["", "", "", "", "", "", "", "", "", ""],
    lcQty: "",
    scId: "",
    scQty: "",
  });

  const lcIdRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const handleInputDataOfLcId = (index, value) => {
    setLcPreparationData((prevData) => {
      const newLcId = [...prevData.lcId];
      newLcId[index] = value;
      return { ...prevData, lcId: newLcId };
    });
    lcIdRefs.current[index].current.focus();
  };

  const handleInputDataOfLcPreparation = (key, value) => {
    setLcPreparationData({ ...lcPreparationData, [key]: value });
  };

  const submitFilledLcPreparationData = async (e) => {
    e.preventDefault();

    const duplicatesLcIds = lcPreparationData.lcId.filter(
      (currLcId, index, lcIdArray) => lcIdArray.indexOf(currLcId) !== index && currLcId !== ""
    );
    const filterLcIdData = lcPreparationData.lcId.filter(
      (currLcId) => currLcId !== ""
    );

    let filterLcPreparationData = {
      lcId: filterLcIdData,
      lcQty: lcPreparationData.lcQty,
      scId: lcPreparationData.scId,
      scQty: lcPreparationData.scQty,
    };

    if (duplicatesLcIds.length > 0) {
      let alertMessage = `Duplicate LC ID found. Please remove duplicate LC ID(s): "${duplicatesLcIds.join(
        ", "
      )}"`;
      toast.error(alertMessage);
    } else {
      try {
        const response = await dispatch(
          fetchToPrepareNewLc(filterLcPreparationData)
        ).unwrap();
        console.log("response", response);
        if (response.success) {
          setLcPreparationData({
            lcId: ["", "", "", "", "", "", "", "", "", ""],
            lcQty: lcPreparationData.lcQty,
            scId: "",
            scQty: lcPreparationData.scQty,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const clearAll = () => {
    setLcPreparationData({
      lcId: ["", "", "", "", "", "", "", "", "", ""],
      lcQty: "",
      scId: "",
      scQty: "",
    });
  };

  const preventEnterKeySubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <form
        onSubmit={submitFilledLcPreparationData}
        className="max-w-sm p-2 sm:mx-auto mx-4 my-2 border rounded-lg shadow-lg justify-center"
      >
        {lcPreparationData.lcId.map((lcId, index) => (
          <div className="p-0.5" key={index}>
            <label
              htmlFor={`lcId${index + 1}`}
              className={`block mt-0.5 text-sm font-medium text-teal-900`}
            >
              <span className="text-black-600 dark:text-black-500">
                LC ID {index + 1}:
              </span>
            </label>
            <input
              type="text"
              id={`lcId${index + 1}`}
              value={lcId}
              name={`lcId${index + 1}`}
              ref={lcIdRefs.current[index]}
              onChange={(e) => handleInputDataOfLcId(index, e.target.value)}
              onKeyDown={preventEnterKeySubmit} // Prevent Enter key
              className="border border-teal-300 rounded-md px-4 py-2 w-full"
              placeholder={`Input LC ID ${index + 1}`}
            />
          </div>
        ))}

        <div className="p-0.5">
          <label
            htmlFor="lcQty"
            className={`block mt-0.5 text-sm font-medium text-teal-900`}
          >
            <span className="text-black-600 dark:text-black-500">
              LC Quantity:
            </span>
          </label>

          <input
            type="number"
            id="lcQty"
            value={lcPreparationData.lcQty}
            name="lcQty"
            onChange={(e) =>
              handleInputDataOfLcPreparation(e.target.name, e.target.value)
            }
            onKeyDown={preventEnterKeySubmit} // Prevent Enter key
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Fill LC Quantity"
            required
            min={0}
          />
        </div>

        <div className="p-0.5">
          <label
            htmlFor="scId"
            className={`block mt-0.5 text-sm font-medium text-teal-900`}
          >
            <span className="text-black-600 dark:text-black-500">SC ID:</span>
          </label>

          <input
            type="text"
            id="scId"
            value={lcPreparationData.scId}
            name="scId"
            onChange={(e) =>
              handleInputDataOfLcPreparation(e.target.name, e.target.value)
            }
            onKeyDown={preventEnterKeySubmit} // Prevent Enter key
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Scan SC ID"
            required
          />
        </div>

        <div className="p-0.5">
          <label
            htmlFor="scQty"
            className={`block mt-0.5 text-sm font-medium text-teal-900`}
          >
            <span className="text-black-600 dark:text-black-500">
              SC Quantity:
            </span>
          </label>

          <input
            type="number"
            id="scQty"
            value={lcPreparationData.scQty}
            name="scQty"
            onChange={(e) =>
              handleInputDataOfLcPreparation(e.target.name, e.target.value)
            }
            onKeyDown={preventEnterKeySubmit} // Prevent Enter key
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Input SC Quantity"
            required
            min={0}
          />
        </div>

        <div className="py-2">
          <button
            type="submit"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Submit
          </button>

          <button
            type="button"
            onClick={() => clearAll()}
            className="ml-2 text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Clear All
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateNewLc;
