import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchToInoculateNewBag } from '../../reduxStore/BagInoculation/BagInoculationSlice';
import QRScanner from '../../assets/QRScanner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InoculateNewBag = () => {
  const dispatch = useDispatch();
  const [bagInoculationData, setBagInoculationData] = useState({
    JamOrLcId: '',
    JamOrLcQty: '',
    bagId: ['', '', '', ''],
    bagQty: ''
  });

  const bagIdRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef()]);

  useEffect(() => {
    if (bagIdRefs.current[0]) {
      bagIdRefs.current[0].current.focus(); // Focus on the first input on mount
    }
  }, []);

  const handleInputDataOfBagID = async (index, value) => {

    setBagInoculationData(prevData => {
      const updatedBagIds = [...prevData.bagId];
      updatedBagIds[index] = value;
      return { ...prevData, bagId: updatedBagIds };
    });



    // if (value.length >= 9) {

    //   try {
    //     const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
    //     // console.log("BACK_HOST",BACK_HOST);

    //     const url = `${BACK_HOST}api/v1/admin/getSingleBagInoculationData/${value}`;
    //     // console.log("url",url);


    //     // const response = await fetch(`http://localhost:4321/api/v1/admin/getSingleBagInoculationData/${value}`);
    //     const response = await fetch(url,{
    //       method:"GET",
    //       credentials: 'include'
    //     });


    //     if (!response.ok) {
    //       if (index < bagIdRefs.current.length - 1 && value) {
    //         bagIdRefs.current[index].current.focus();
    //       }
    //     }


    //   } catch (error) {
    //     console.log("Not Added BagID")
    //   }


    // }

  };



  const handleInputDataOfBagInoculation = (key, value) => {
    setBagInoculationData(prevData => ({
      ...prevData,
      [key]: value
    }));

  };

  const submitFilledBagInoculationData = async e => {
    e.preventDefault();

    // let bagId = ['1223', '123', '098', '123'];

    // Find elements that appear more than once
    let duplicates = bagInoculationData.bagId.filter((item, index, arr) => arr.indexOf(item) !== index && item !== '');
    // console.log("bagInoculationData.bagId", bagInoculationData.bagId);
    let FilterBagIdData = bagInoculationData.bagId.filter((currBagId)=>currBagId !== '')

    let newBagInoculationData = {
      JamOrLcId:bagInoculationData.JamOrLcId,
      JamOrLcQty:bagInoculationData.JamOrLcQty,
      bagQty:bagInoculationData.bagQty,
      bagId: FilterBagIdData,
    }


    // console.log("duplicates1", duplicates);
    // console.log("FilterBagIdData1", FilterBagIdData);
    
    if (duplicates.length > 0) {
      let alertMessage = `Duplicate Bag ID found. Please remove duplicate Bag ID(s): "${duplicates.join(", ")}"`;
      toast.error(alertMessage);
    }else{
      try {
       

        const response = await dispatch(fetchToInoculateNewBag(newBagInoculationData)).unwrap();
        if (response.success) {
          setBagInoculationData({
            JamOrLcId: '',
            JamOrLcQty: bagInoculationData.JamOrLcQty,
            bagId: ['', '', '', ''],
            bagQty: bagInoculationData.bagQty
          });
        }
      } catch (error) {
        console.error('Error during submission:', error);
      }
    }



  };

  const clearAll = () => {
    setBagInoculationData({
      JamOrLcId: '',
      JamOrLcQty: '',
      bagId: ['', '', '', ''],
      bagQty: ''
    });
  };

  //!This was for 
  // const bagQtyResult = useCallback(result => {
  //   setBagInoculationData(prevData => ({ ...prevData, bagQty: result }));
  // }, []);

  // const JamOrLcQtyResult = useCallback(result => {
  //   setBagInoculationData(prevData => ({ ...prevData, JamOrLcQty: result }));
  // }, []);

  return (
    <form
      onSubmit={submitFilledBagInoculationData}
      className="max-w-sm bg-white-50 p-2 sm:mx-auto mx-4 my-3 rounded-lg shadow-lg justify-center"
    >

      {bagInoculationData.bagId.map((bag, index) => (
        <div key={index} className="p-0.5">
          <label htmlFor={`bagId${index}`} className="block  text-[13.5px] font-medium">
            Bag Id {index + 1}:
          </label>
          <input
            type="text"
            id={`bagId${index}`}
            ref={bagIdRefs.current[index]} // Use array of refs
            value={bag}
            onChange={e => handleInputDataOfBagID(index, e.target.value)}
            className="block w-full p-1.5 text-black-900 border border-teal-300 rounded-lg bg-white-50 text-base focus:ring-teal-500 focus:border-teal-500"
            placeholder={`Scan Bag ID ${index + 1}`}
           
          />
        </div>
      ))}

      <div className="p-0.5">
        <label htmlFor="bagId" className="block text-[13.5px] font-medium ">
          Bag Quantity:
        </label>
        <input
          type="number"
          id="bagQty"
          value={bagInoculationData.bagQty}
          onChange={e => handleInputDataOfBagInoculation('bagQty', e.target.value)}
          className="block w-full p-1.5 text-black-900 border border-teal-300 rounded-lg bg-white-50 text-base focus:ring-teal-500 focus:border-teal-500"
          placeholder="Input Bag Quantity"
          required
          min={0}
        />
      </div>

      <div className="p-0.5">
        <label htmlFor="JamOrLcId" className="block text-[13.5px] font-medium">
          JAM/LC Id:
        </label>
        <input
          type="text"
          id="JamOrLcId"
          value={bagInoculationData.JamOrLcId}
          onChange={e => handleInputDataOfBagInoculation('JamOrLcId', e.target.value)}
          className="block w-full p-1.5 text-black-900 border border-teal-300 rounded-lg bg-white-50 text-base focus:ring-teal-500 focus:border-teal-500"
          placeholder="Input Jam/LC ID"
          required
        />
      </div>

      <div className="p-0.5">
        <label htmlFor="bagId" className="block text-[13.5px] font-medium ">
          JAM/LC Quantity:
        </label>
        <input
          type="number"
          id="JamOrLcQty"
          value={bagInoculationData.JamOrLcQty}
          onChange={e => handleInputDataOfBagInoculation('JamOrLcQty', e.target.value)}
          className="block w-full p-1.5 text-black-900 border border-teal-300 rounded-lg bg-white-50 text-base focus:ring-teal-500 focus:border-teal-500"
          placeholder="Fill Jam/LC Quantity"
          required
          min={0}
        />
      </div>

      <div className="p-0.5">
        <button
          type="submit"
          className="mr-2 ml-0 text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"
        >
          Submit
        </button>
        <button
          type="button"
          onClick={clearAll}
          className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5"
        >
          Clear All
        </button>
      </div>
    </form>
  );
};

export default InoculateNewBag;
