import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBagInoculationData, fetchDeletingBagInoculationData, fetchToUpdateBagInoculationData } from '../../../reduxStore/BagInoculation/BagInoculationSlice';
import Loader from '../../../assets/Loader'; 
import FormatTime from '../../../assets/FormatTime';

const AdminDataOfBagInoculation = () => {
  const state = useSelector((state) => state.bagInoculationReducer);
  const dispatch = useDispatch();
  const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);
  const [updatingId, setUpdatingId] = useState(null);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [fillingDataForUpdateTheCurrValue, setFillingDataForUpdateTheCurrValue] = useState({});

  useEffect(() => {
    dispatch(fetchBagInoculationData());
  }, [dispatch]);

  useEffect(() => {
    if (state && state.data && Array.isArray(state.data.bagInoculationData)) {
      setGetAllDataFromCollection(state.data.bagInoculationData);
    }
  }, [state]);


  const deleteData = (id) => {
    if (window.confirm("Do you really want to delete?")) {
      dispatch(fetchDeletingBagInoculationData(id))
        .then(() => {
          dispatch(fetchBagInoculationData());
        })
        .catch(error => {
          console.error("Error deleting data:", error);
        });
    }
  };

  const updateData = (id) => {
    setUpdatingId(id);
    setDisabledButtons(true);
    const updatingData = getAllDataFromCollection.find((item) => item._id === id);
    if (updatingData) {
      setFillingDataForUpdateTheCurrValue(updatingData);
    } else {
      console.error("No data found for the provided id");
    }
  };

  const handleInputData = (key, value) => {
    setFillingDataForUpdateTheCurrValue({ ...fillingDataForUpdateTheCurrValue, [key]: value });
  };

  const save = async (id) => {
    const { inoculationTimeStamp, previousStatusTillLCPreparation, __v, _id, ...onlyRequiredValue } = fillingDataForUpdateTheCurrValue;
    dispatch(fetchToUpdateBagInoculationData({ id, filledData: onlyRequiredValue })).then(() => {
      setUpdatingId(null);
      setDisabledButtons(false);
      setFillingDataForUpdateTheCurrValue({});
      dispatch(fetchBagInoculationData());
    });
  };

  const updateCancel = () => {
    setUpdatingId(null);
    setDisabledButtons(false);
    setFillingDataForUpdateTheCurrValue({});
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block">
          <div className="border rounded-lg shadow overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">S.No.</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Bag ID</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Bag Quantity</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Jam/LC ID</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Jam/LC Quantity</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Inoculated By</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Inoculation Time </th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Remove</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Update</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {state.isLoading && (
                  <tr>
                    <td colSpan="8" className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                      <Loader />
                    </td>
                  </tr>
                )}
                {Array.isArray(getAllDataFromCollection) && getAllDataFromCollection.map((currItem,index) => (
                  <tr className="hover:bg-teal-300" key={currItem._id}>
                    {updatingId && currItem._id === updatingId ? (
                      <>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                          <input
                            type="text"
                            id="bagId"
                            value={fillingDataForUpdateTheCurrValue.bagId}
                            onChange={(e) => handleInputData(e.target.name, e.target.value)}
                            name="bagId"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Bag Id"
                            required
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                          <input
                            type="number"
                            id="bagQty"
                            value={fillingDataForUpdateTheCurrValue.bagQty}
                            onChange={(e) => handleInputData(e.target.name, e.target.value)}
                            name="bagQty"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Bag Qty."
                            required
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                          <input
                            type="text"
                            id="JamOrLcId"
                            value={fillingDataForUpdateTheCurrValue.JamOrLcId}
                            onChange={(e) => handleInputData(e.target.name, e.target.value)}
                            name="JamOrLcId"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Jam/Lc Id"
                            required
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                          <input
                            type="number"
                            id="JamOrLcQty"
                            value={fillingDataForUpdateTheCurrValue.JamOrLcQty}
                            onChange={(e) => handleInputData(e.target.name, e.target.value)}
                            name="JamOrLcQty"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Jam/LC Qty"
                            required
                          />
                        </td>
                    
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                          <input
                            type="text"
                            id="inoculatedBy"
                            value={fillingDataForUpdateTheCurrValue.inoculatedBy}
                            onChange={(e) => handleInputData(e.target.name, e.target.value)}
                            name="inoculatedBy"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Inoculated By"
                            required
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                          <input
                            type="text"
                            id="inoculationTimeStamp"
                            value={fillingDataForUpdateTheCurrValue.inoculationTimeStamp}
                            onChange={(e) => handleInputData(e.target.name, e.target.value)}
                            name="inoculationTimeStamp"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="No Need To Filled"
                            required
                            disabled
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                          <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none" onClick={() => { deleteData(currItem._id) }} disabled={disabledButtons}>Remove</button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                          <div className="flex justify-between">
                            <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-teal-600 hover:text-teal-800 disabled:opacity-50 disabled:pointer-events-none mr-2" onClick={() => { save(currItem._id) }} >Save</button>
                            <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-yellow-600 hover:text-yellow-800 disabled:opacity-50 disabled:pointer-events-none" onClick={updateCancel}>Cancel</button>
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{index+1}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.bagId}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.bagQty}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.JamOrLcId}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.JamOrLcQty}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.inoculatedBy}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currItem.inoculationTimeStamp)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                          <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none" onClick={() => { deleteData(currItem._id) }} disabled={disabledButtons}>Remove</button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                          <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-600 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none" onClick={() => { updateData(currItem._id) }} disabled={disabledButtons}>Edit</button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDataOfBagInoculation;
