import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import QRScanner from '../../assets/QRScanner';
import { toast } from "react-toastify";

const PlaceMould = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // console.log("received props :", location);
    const [mouldIds, setMouldIds] = useState([]);
    const [humidifier, setHumidifier] = useState(location.state.humidifier);
    const addMouldId = (res) => {
        // console.log("res: ", res)
        setMouldIds([...mouldIds, res])
        // console.log("Mould Ids : ", mouldIds)
    }
    const handleSubmit = async () => {
        try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;

            const url = `${BACK_HOST}api/v1/humidifier/addMoulds`;
            let data = {
                "humidifierNo": humidifier.serialNo,
                "moldIds": mouldIds,

                "placedBy": "Manish", // todo : update as per user name
                "size": "small",
            }
            console.log("Place Mould Data: ", data)
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            console.log(" Place Mould response: ", response)
            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                return errorData
            }
            setMouldIds([]);

            const res = await response.json();
            console.log("Response Place Mould submit res:", res.updatedHumidifier);
            toast.success(res.message);
            // navigate("/")
            navigate('/placeMoreMoulds', { state: { humidifier: res.updatedHumidifier } })
            return res;

        } catch (error) {
            console.error("Error in adding moulds ", error);
            throw error;
        }
    }


    const removeMould = (e, id) => {
        e.preventDefault();
        setMouldIds(mouldIds.filter(mouldId => mouldId !== id));
        console.log()
    };

    return (
        <div className='mx-4'>
            <div>
                {console.log("Humidifier: ", location.state.humidifier, humidifier)}
                <h2 className='text-2xl font-bold m-2 text-center'>Humidifier : {humidifier.serialNo}</h2>
            </div>
            <form className="max-w-sm  p-2 sm:mx-auto my-2 border  rounded-lg shadow-lg justify-center">
                <div className='hidden'>
                    <label htmlFor="mouldId" className="block mt-2 text-sm font-medium text-teal-900">
                        Scan Mould id
                        <QRScanner idName={"mouldScanner"} buttonName={"Scan mould"} resultOnScanSuccess={addMouldId} keepScanning={true} />
                    </label>
                </div>
                <div className='p-0.5'>
                    <label htmlFor="mouldId" className="block mt-2 text-sm font-medium text-teal-900">
                        Scan Mould ID
                    </label>
                    <input type="text" id="mouldId" value={''} name="mouldId"
                        className="border border-teal-300 rounded-md px-4 py-2 w-full"
                        placeholder="Input Mould Id"
                    />
                </div>

                <label for="scanMultipleMouldIds" class="block mb-2 text-sm font-medium text-teal-900 ">Scanned Moulds IDs</label>
                <div className='max-h-48 overflow-auto'>
                    {mouldIds.map((id) => (
                        <div
                            key={id}
                            className="flex flex-wrap justify-between items-center bg-gray-100 p-2 mb-2 rounded shadow"
                        >
                            <span>{id}</span>
                            <button
                                onClick={(e) => removeMould(e, id)}
                                className="text-red-500 hover:text-red-700"
                            >
                                &times;
                            </button>
                        </div>
                    ))}
                </div>
                <div className='p-2'>
                    <button type="button" onClick={handleSubmit} class="text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Submit</button>

                    <button type="button" class="ml-1 text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => navigate('/')}>Cancel</button>
                </div>



            </form>

        </div>
    )
}

export default PlaceMould
