import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import QRScanner from '../../assets/QRScanner';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAddedMouldsData } from '../../reduxStore/Humidifier/HumidifierSlice'

const PlaceMoreMoulds = () => {
  const state = useSelector((state) => state.growthChamberReducer)
  // console.log("state", state);
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("received props :", location);
  const [addedMouldData, setAddedMouldsData] = useState({});
  const [humidifier, setHumidifier] = useState(location.state.humidifier);
  console.log("humidifier", humidifier);
  const [mouldIds, setMouldIds] = useState([]);
  const addMouldId = (res) => {
    // console.log("res: ", res)
    setMouldIds([...mouldIds, res])
    // console.log("Mould Ids : ", mouldIds)
  }

  const handleSubmit = async () => {
    try {
      // const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST_URL;
      const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
      // const url = `${BACK_HOST}humidifier/addMoulds`;
      console.log("humidifier.dataId", humidifier.dataId);
      const url = `${BACK_HOST}api/v1/humidifier/addMoreMouldsToHumidifier?id=${humidifier.dataId}`;
      let data = {
        'moldIds': mouldIds,
      }
      console.log("Place Mould Data: ", data)
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      })
      console.log(" Place Mould response: ", response)
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
        return errorData
      }
      setMouldIds([]);

      const res = await response.json();
      console.log("Response Placing More Mould submit res:", res);
      toast.success(res.message);
      // dispatch(getAddedMouldsData({ humidifier }))
      // navigate('/')
      return res;

    } catch (error) {
      console.error("Error in adding moulds ", error);
      throw error;
    }
  }

  // Function to remove a bag ID from the list
  const removeMould = (e, id) => {
    e.preventDefault();
    setMouldIds(mouldIds.filter(mouldId => mouldId !== id));
    console.log()
  };

  const donePlacingMould = async () => {
    const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
    console.log("humidifier._id", humidifier._id);
    const url = `${BACK_HOST}api/v1/humidifier/filledCompletely?id=${humidifier._id}`;
    console.log("url", url);
    try {
      const response = await fetch(url, {
        method: 'PUT',
        // credentials:true
      })
      // console.log(" Completely Filled response: 1", response)
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
        return errorData
      }

      const res = await response.json();
      // console.log(" Completely Filled response: 2", res)
      toast.success(res.message);
      navigate('/')
      return res;

    } catch (error) {
      console.error("Error in Humidifier filled completely ", error);
      throw error;
    }
  }


  useEffect(() => {
    dispatch(getAddedMouldsData({ humidifier }));
  }, [dispatch]);

  useEffect(() => {
    if (state && state.data && state.data.data) {
      setAddedMouldsData(state.data.data);
    }
  }, [state]);


  return (
    <div className='mx-4'>
      <div>
        {/* {console.log("Humidifier: ", location.state.humidifier, humidifier)} */}
        <h2 className='text-3xl font-bold m-4 text-center'>Add more moulds to Humidifier : {humidifier.serialNo}</h2>
      </div>
      <form className="max-w-sm mx-auto bg-teal-50 p-4 border border-teal-200 rounded-lg shadow-lg">
        <div className='flex justify-between items-center '>
          <h2 className='text-lg font-bold text-center'>Humidifier : {humidifier.serialNo}</h2>
          <button type="button" onClick={donePlacingMould} class="text-white bg-orange-400 hover:bg-orange-500 focus:outline-none focus:ring-4 focus:ring-orange-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-orange-900">Filled Humidifier</button>

        </div>

        <div>
          <label htmlFor="bagId" className="block mt-2 text-sm font-medium text-teal-900">
            Scan Mould id
            <QRScanner idName={"mouldScanner"} buttonName={"Scan Mould IDs"} resultOnScanSuccess={addMouldId} keepScanning={true} />
          </label>
        </div>
        <label for="scanMultipleMouldIds" class="block mb-2 text-sm font-medium text-teal-900 ">Scanned Moulds IDs</label>
        <div className='max-h-48 overflow-auto'>
          {mouldIds.map((id) => (
            <div
              key={id}
              className="flex flex-wrap justify-between items-center bg-gray-100 p-2 mb-2 rounded shadow"
            >
              <span>{id}</span>
              <button
                onClick={(e) => removeMould(e, id)}
                className="text-red-500 hover:text-red-700"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
        <div className='p-2'>
          <button type="button" onClick={handleSubmit} class="text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Submit</button>

          <button type="button" class="text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => navigate('/')}>Cancel</button>
        </div>



      </form>


      <div className="relative overflow-x-auto shadow-md rounded-lg mx-4 mt-2 mb-2">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 ">
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr className='hover:bg-teal-300'>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border text-lg font-bold">Batch ID :</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{addedMouldData?.batchId}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800  text-lg font-bold">Mould IDs:</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border col-span-6" colSpan="6">
                {addedMouldData?.moldIds?.join(', ')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}



export default PlaceMoreMoulds
