import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../assets/Loader';
import { fetchJamPreparationData, fetchToDeleteJamPreparationData, fetchToUpdateJamPreparationData } from '../../../reduxStore/JamPreparation/JamPreparationSlice';
import convertToIST from '../../../assets/FormatTime';

const AdminDataOfJamPreparation = () => {
    const state = useSelector((state) => state.jamPreparationReducer);
    const dispatch = useDispatch();
    const [getAllDataOfPrepareJam, setGetAllDataOfPrepareJam] = useState([]);
    const [updateBagId, setUpdateBagId] = useState(null);
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [fillingDataForUpdateTheCurrValue, setFillingDataForUpdateTheCurrValue] = useState({});

    useEffect(() => {
        dispatch(fetchJamPreparationData());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.dataOfAllPreparedJam) {
            setGetAllDataOfPrepareJam(state.data.dataOfAllPreparedJam);
        }
    }, [state]);

    const deleteData = async (id) => {
        if (window.confirm("Do you really want to delete?")) {
            
            dispatch(fetchToDeleteJamPreparationData(id)).then(() => {
                dispatch(fetchJamPreparationData());
            });
        }
    }

    const updateData = (id) => {
        setUpdateBagId(id);
        setDisabledButtons(true);
        const updatingData = getAllDataOfPrepareJam.find((currItem) => currItem._id === id);
        if (updatingData) {
            setFillingDataForUpdateTheCurrValue(updatingData);
        } else {
            console.error("No data found for the provided id");
        }
    }

    const handleInputData = (key,value) => {
        setFillingDataForUpdateTheCurrValue({...fillingDataForUpdateTheCurrValue , [key]:value});
    }


    const save = async (id) => {
        const { jamPreparationTime, __v, _id, ...onlyRequiredValue } = fillingDataForUpdateTheCurrValue;
        dispatch(fetchToUpdateJamPreparationData({id,filledData:onlyRequiredValue})).then(() => {
            setUpdateBagId(null);
            setDisabledButtons(false);
            setFillingDataForUpdateTheCurrValue({});
            dispatch(fetchJamPreparationData());
        })
    }

    const updateCancel = () => {
        setUpdateBagId(null);
        setDisabledButtons(false);
        setFillingDataForUpdateTheCurrValue({});
    }

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block">
                    <div className="border rounded-lg shadow overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">S.No.</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Jam Id</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Jam Quantity(in ml.)</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Qty.(in ml)</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Jam Preparation Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Jam Prepared By</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Remove</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Update</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {state.isLoading &&
                                    <tr>
                                        <td colSpan={'8'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                }

                                {getAllDataOfPrepareJam.map((currData,index) => (
                                    <tr className='hover:bg-teal-300' key={currData._id}>
                                        {updateBagId && currData._id === updateBagId ? (
                                            <>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.jamId} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="jamId" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Id" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="Number" value={fillingDataForUpdateTheCurrValue.jamQty} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="jamQty" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Quantity" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.lcId} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="lcId" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="LC Id" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="Number" value={fillingDataForUpdateTheCurrValue.lcQty} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="lcQty" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="LC Quantity" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.jamPreparationTime} onChange={(e) => handleInputData(e.target.name, e.target.value)} disabled={disabledButtons} name="jamPreparationTime" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.jamPreparedBy} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="jamPreparedBy" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Prepared By" required />
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { deleteData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Remove
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { save(currData._id) }} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-500 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none mr-2">
                                                        Save
                                                    </button>
                                                    <button type="button" onClick={() => { updateCancel(currData._id) }} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-yellow-600 hover:text-yellow-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Cancel
                                                    </button>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{index+1}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.jamId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.jamQty}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcQty}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{convertToIST(currData.jamPreparationTime)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.jamPreparedBy}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { deleteData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Remove
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { updateData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-600 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none">Edit</button>
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminDataOfJamPreparation;
