import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../assets/Loader';
import { fetchToGetLcRejectionData, fetchToDeleteLcRejectionData , fetchToUpdateLcRejectionData} from '../../../reduxStore/LcRejection/LcRejectionSlice';
import FormatTime from '../../../assets/FormatTime'

const AdminDataOfLcRejection = () => {
    const state = useSelector((state) => state.lcRejectionReducer); // Adjusted the selector name
    const dispatch = useDispatch();
    const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);
    const [updatingId, setUpdatingId] = useState(null);
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [fillingDataForUpdateTheCurrValue, setFillingDataForUpdateTheCurrValue] = useState({});

    useEffect(() => {
        dispatch(fetchToGetLcRejectionData());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.dataOfAllRejectedLc) {
            setGetAllDataFromCollection(state.data.dataOfAllRejectedLc);
        }
    }, [state]);

    const deleteData = async (id) => {
        if (window.confirm("Do you really want to delete?")) {
            
            dispatch(fetchToDeleteLcRejectionData(id)).then(() => {
                dispatch(fetchToGetLcRejectionData());
            });
        }
    }

    const updateData = (id) => {
        setUpdatingId(id);
        setDisabledButtons(true);
        const updatingData = getAllDataFromCollection.find((currItem) => currItem._id === id);
        if (updatingData) {
            setFillingDataForUpdateTheCurrValue(updatingData);
        } else {
            console.error("No data found for the provided id");
        }
    }

    const handleInputData = (key,value) => {
        setFillingDataForUpdateTheCurrValue({...fillingDataForUpdateTheCurrValue , [key]:value});
    }

    const save = async (id) => {
        const { lcRejectionTime, __v, _id, previousStatusTillLCPreparation , ...onlyRequiredValue  } = fillingDataForUpdateTheCurrValue;


        dispatch(fetchToUpdateLcRejectionData({id,updatedData:onlyRequiredValue})).then(() => {
            setUpdatingId(null);
            setDisabledButtons(false);
            setFillingDataForUpdateTheCurrValue({});
            dispatch(fetchToGetLcRejectionData());
        })
    }

  

    const updateCancel = () => {
        setUpdatingId(null);
        setDisabledButtons(false);
        setFillingDataForUpdateTheCurrValue({});
    }


    console.log("getAllDataFromCollection",getAllDataFromCollection);
    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block">
                    <div className="border rounded-lg shadow overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">S.No.</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Reason Of Rejection</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Rejected</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Quantity(in ml)</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">SC Id</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">SC Quantity(in ml.)</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Preparation Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Rejection Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Remove</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Update</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {state.isLoading &&
                                    <tr>
                                        <td colSpan={'8'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                }

                                {(Array.isArray(getAllDataFromCollection) ? getAllDataFromCollection : [] ).map((currData,index) => (
                                    <tr className='hover:bg-teal-300' key={currData._id}>
                                        {updatingId && currData._id === updatingId ? (
                                            <>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.lcId} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="lcId" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Id" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.reasonOfRejection} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="reasonOfRejection" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Quantity" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={fillingDataForUpdateTheCurrValue.lcRejectedBy} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="lcRejectedBy" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="LC Id" required />
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={currData.previousStatusTillLCPreparation.lcQty}  name="lcQty" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="LC Quantity" required />
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={currData.previousStatusTillLCPreparation.scId}  name="scId" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Prepared By" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={currData.previousStatusTillLCPreparation.scQty}  name="scQty" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Prepared By" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={FormatTime(currData.previousStatusTillLCPreparation.lcPreparationTime)}  name="lcPreparationTime" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Prepared By" required />
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                    <input type="String" value={FormatTime(currData.lcRejectionTime)}  name="lcRejectionTime" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Jam Prepared By" required />
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { deleteData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Remove
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { save(currData._id) }} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-500 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none mr-2">
                                                        Save
                                                    </button>
                                                    <button type="button" onClick={() => { updateCancel(currData._id) }} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-yellow-600 hover:text-yellow-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Cancel
                                                    </button>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{index+1}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.reasonOfRejection}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcRejectedBy}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousStatusTillLCPreparation.lcQty}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousStatusTillLCPreparation.scId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousStatusTillLCPreparation.scQty}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.previousStatusTillLCPreparation.lcPreparationTime)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.lcRejectionTime)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { deleteData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Remove
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { updateData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-600 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none">Edit</button>
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminDataOfLcRejection;
